import React, { useContext, useEffect, useMemo, useState } from 'react';
import Slider from '../CustomSlider';

import RecommendedProductsList from './RecommendedProductsList';

import { useUpsellProducts } from '../../hooks/cart';
import { isWishlistApplicable } from '../../constants/feature';
import InlineLoader from '../InlineLoader';
import appContext from '../../Context/appContext';
import { isRecommendedCartUpsellApplicable, isAddToCartRecommendationApplication } from '../../constants/feature';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

const recommendedProductsSlider = {
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 3,
  arrows: true,
  centerMode: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: true
      }
    }
  ]
};

const otherProductsId = `otherProductsYouMayLike`;

const RecommendedProducts = ({ className, cartType = '', isLoading = false, forCheckout, showUpsellProducts = true, showAllCartProductsWidget = false }) => {
  // const {
  //   state: {
  //     cart: { upsellRecommendedProducts, upsellPopularProducts }
  //   }
  // } = useContext(appContext);

  const { upsellRecommendedProducts, upsellPopularProducts } = useSelector(state => state.cart) || {}

  const {
    data: { upsellProducts, upsellZone }
  } = useUpsellProducts();

  let finalUpsellProducts = null;

  const router = useRouter();
  const isCheckoutPage = router.pathname === "/checkout";

  const updateViewCartProducts = () => {
    finalUpsellProducts = [];

    if (upsellProducts?.length) {
      finalUpsellProducts.push({ id: isWishlistApplicable ? otherProductsId : 'upsell_products', title: `Other products you may like`, products: showUpsellProducts && upsellProducts?.length ? upsellProducts : [] });
    }

    if (upsellRecommendedProducts && isRecommendedCartUpsellApplicable) {
      Object.keys(upsellRecommendedProducts).forEach((keys, i) => {
        finalUpsellProducts.push({
          id: 2,
          title: `${upsellRecommendedProducts[keys]?.label}`,
          products: !isCheckoutPage && upsellRecommendedProducts[keys]?.categories?.length ? upsellRecommendedProducts[keys].categories : []
        });
      });
    }

    if (upsellPopularProducts?.length && !isCheckoutPage && isRecommendedCartUpsellApplicable) {
      let productIds = finalUpsellProducts[1]?.products.map(item => item.product_id) || [];
      let filteredRecProducts = upsellPopularProducts.filter(item => !productIds.includes(item.product_id));

      finalUpsellProducts.push({
        title: `Recommended Products`,
        products: filteredRecProducts
      });
    }

    return finalUpsellProducts;
  };

  if (showAllCartProductsWidget || forCheckout) {
    updateViewCartProducts();
  }

  if (!finalUpsellProducts?.length && !upsellProducts?.length && !isLoading) {
    return null;
  }

  return (
    <div className={`recommended-products ${className}`}>
      {isRecommendedCartUpsellApplicable && isLoading ? (
        <div className="text-center my-2 mb-4 pb-4 loader-parent">
          <InlineLoader positionRelative className="big" />
        </div>
      ) : finalUpsellProducts?.length ? (
        <>
          {finalUpsellProducts.map((item, index) =>
            item.products?.length ? (
              <>
                {item.id === otherProductsId ? null : <label className="mini-label">{item.title}</label>}
                <Slider {...recommendedProductsSlider} className="mb-3">
                  <RecommendedProductsList upsellProducts={item.products} categoryType={item.title} cartType={cartType} categoryIndex={index} upsellZone={upsellZone} />
                </Slider>
                {item.id === otherProductsId ? <hr /> : null}
              </>
            ) : null)}
        </>
      ) : null
      }
    </div >
  );
};

export default RecommendedProducts;
