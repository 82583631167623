import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import appContext from '../../Context/appContext';
import useSegment from '../../hooks/segment';
import ProductTileInCart from './ProductTileInCart';

const RecommendedProductsList = ({ upsellProducts, upsellZone, categoryType, categoryIndex }) => {
  const { trackEvent } = useSegment();
  const { couponData, isLoggedIn } = useSelector(state => state.user)
  const cartItems = useSelector(state => state.cart?.cartData?.cart_items) || []
  const { discount_code: discountCode } = couponData;

  useEffect(() => {
    if (process.browser) {
      const origin = window.location.origin;
      const products = [];
      if (upsellProducts && upsellProducts.length) {
        upsellProducts.forEach((item, index) => {
          products.push({
            title: categoryType,
            products: {
              product_id: item.product_details_id,
              sku: item.bundle_id ? item.bundle_id : item.master_product_id,
              name: item.product_name,
              strain_type: item.product_strain_type_name || '',
              price: item.price,
              original_price: item.price_without_deal,
              position: index,
              product_attributes: Array.isArray(item.product_attributes_second) ? item.product_attributes_second.join('/') : '',
              brand: Array.isArray(item.brand_names) ? item.brand_names.join('/') : item.brand_slug,
              variant: item.product_unit,
              quantity: 1,
              coupon: discountCode || '',
              category_frontend: item.category_name,
              category: item.full_shop_category_name,
              url: `${origin}/product/${item.slug}`,
              image_url: item.product_image
            }
          });
        });
      }

      trackEvent('Product List Viewed', {
        list_id: `cart_addons_${upsellZone}`,
        category: 'Deals',
        products
      }, !isLoggedIn);

      trackEvent('Promotion Viewed', {
        list_id: `cart_addons_${upsellZone}`,
        category: 'Upsell Products',
        products
      }, true);
    }
  }, []);

  return (
    <>
      {upsellProducts.map((element, index) => (
        <ProductTileInCart
          key={`upsell-${element.product_details_id}`}
          element={element}
          categoryType={categoryType}
          cartItems={cartItems}
          categoryIndex={categoryIndex}
          productIndex={index}
        />
      ))}
    </>
  );
};

export default RecommendedProductsList;
